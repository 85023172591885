const translations = {
  statement: {
    operation: {
      debit: 'Débito',
      credit: 'Crédito',
    },
    origin: {
      designer: 'Projetista',
      seller: 'Vendedor',
      referrer: 'Parceiro',
      lecturer: 'Conferente',
      manual_designer: 'M. Projetista',
      manual_seller: 'M. Vendedor',
      manual_referrer: 'M. Parceiro',
      manual_lecturer: 'M. Conferente',
    },
    product_type: {
      planned: 'Planejado',
      loose: 'Solto',
    },
  }
};

export function translateValue(model, field, value) {
  if (!translations[model] || !translations[model][field] || !translations[model][field][value]) {
    return value;
  }

  return translations[model][field][value];
}