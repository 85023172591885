import { Box, Container, FormControlLabel, LinearProgress, makeStyles, Paper, Switch } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import AppDataGrid from "../../components/data/app-data-grid";
import GeneralErrors from "../../components/form/general-errors";
import LayoutLogged from "../../components/layout/logged";
import api from "../../services/api";
import { formatDateTimeDay } from "../../services/date";
import { translateValue } from "../../services/translation";
import { getCurrentUser, ifNotHaveAccessRedirect } from "../../services/auth";

const useStyles = makeStyles((theme) => ({
  resume: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(1),
      width: theme.spacing(30),
      height: theme.spacing(16),
      padding: 10
    },
  },
  title: {
    fontSize: 12,
    color: 'gray',
  },
  amount: {
    fontSize: 24,
    textAlign: 'center',
    paddingTop: 30
  },
  link: {
    textDecoration: 'none',
    color: 'blue',
    cursor: 'pointer',
  },
  linkSelected: {
    color: 'green',
  }
}));

export default function StatementIndex() {
  const classes = useStyles();
  const [resume, setResume] = useState();
  const [errors, setErrors] = useState();
  const [query, setQuery] = useState('');
  const [params, setParams] = useState();
  const [lastPaymentsChecked, setLastPaymentsChecked] = useState(false);
  const [refresh, doRefresh] = useState(0);
  const [user] = useState(getCurrentUser());

  const changeQuery = (q) => {
    if (q === query) {
      setQuery('');
    } else {
      setQuery(q);
    }

    if (q !== 'last_payments') {
      setLastPaymentsChecked(false);
    }

    doRefresh(refresh + 1);
  };

  const changeLastPayments = (e) => {
    setLastPaymentsChecked(e.target.checked);

    if (e.target.checked) {
      changeQuery('last_payments');
    } else {
      changeQuery('');
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const response = await api.get(`/statement/resume`, {
          params: params
        });
        setResume(response.data);
      } catch (e) {
        setErrors(e?.response?.data || 'Erro desconhecido');
      }
    })();
  }, [params]);

  if (ifNotHaveAccessRedirect('statement.write')) return <></>;

  return (
    <LayoutLogged title="Comissões">
      <Container>
        {errors && <GeneralErrors errors={errors} />}
        
        {!resume ? <LinearProgress /> : (
          <>
            <div className={classes.resume}>
              <Paper>
                <span className={classes.title}>À PAGAR: COLABORADORES</span>
                <div className={classes.amount}>
                  <span onClick={() => changeQuery('pay_internal')} className={`${classes.link}` + (query === 'pay_internal' ? ` ${classes.linkSelected}` : '')}>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(resume?.to_pay_internal)}</span>
                </div>
              </Paper>

              <Paper>
                <span className={classes.title}>À PAGAR: PARCEIROS</span>
                <div className={classes.amount}>
                  <span onClick={() => changeQuery('pay_referrer')} className={`${classes.link}` + (query === 'pay_referrer' ? ` ${classes.linkSelected}` : '')}>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(resume?.to_pay_referrer)}</span>
                </div>
              </Paper>

              <Paper>
                <span className={classes.title}>AGUARDANDO CONFERÊNCIA</span>
                <div className={classes.amount}>
                  <span onClick={() => changeQuery('pending')} className={`${classes.link}` + (query === 'pending' ? ` ${classes.linkSelected}` : '')}>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(resume?.pending)}</span>
                </div>
              </Paper>

              <Paper>
                <span className={classes.title}>SALDO - PAGO VS DEVIDO</span>
                <div className={classes.amount}>
                  <span onClick={() => changeQuery('diff_auditor')} className={`${classes.link}` + (query === 'diff_auditor' ? ` ${classes.linkSelected}` : '')}>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(resume?.balance)}</span>
                </div>
              </Paper>
            </div>
          </>
        )}

        {query !== undefined && resume && (
          <AppDataGrid
            api={`/statement?q=${query}`}
            linksPath='/statement'
            viewLink='/statement/view'
            refresh={refresh}
            showButtonNew={user?.permissions?.includes('statement.manually.write')}
            toolbar={
              <Box p={1}>
                <FormControlLabel
                  control={
                    <Switch
                      onChange={changeLastPayments}
                      checked={lastPaymentsChecked}
                      color="primary"
                    />
                  }
                  label="Últimos pagamentos"
                />
              </Box>
            }
            columns={[
              {
                field: 'operation', type: 'string', headerName: 'Referência', width: 120, renderCell: (params) => {
                  const translated = translateValue('statement', 'operation', params.value);

                  if (!params.row.debit_id) {
                    return translated;
                  }

                  return (
                    <>
                      {translated} <CheckCircleOutlineIcon fontSize="small" style={{ color: 'green' }} />
                    </>
                  );
                }
              },
              {
                field: 'reference', type: 'string', headerName: 'Referência', width: 120, valueGetter: (params) => {
                  return formatDateTimeDay(params?.row?.reference);
                }
              },
              {
                field: 'origin', type: 'string', headerName: 'Origem', width: 100, valueGetter: (params) => {
                  return translateValue('statement', 'origin', params?.row?.origin);
                }
              },
              {
                field: 'product_type', type: 'string', headerName: 'Produto', width: 100, valueGetter: (params) => {
                  return translateValue('statement', 'product_type', params?.row?.product_type);
                }
              },
              {
                field: 'lead_client', type: 'string', headerName: 'Cliente', width: 200, valueGetter: (params) => {
                  return params.row.lead_client.name;
                }
              },
              {
                field: 'user', type: 'string', headerName: 'Usuário', width: 150, valueGetter: (params) => {
                  return params?.row?.user?.name;
                }
              },
              {
                field: 'referrer_organization', type: 'string', headerName: 'Organização', width: 150, valueGetter: (params) => {
                  return params?.row?.referrer_organization?.name;
                }
              },
              {
                field: 'amount', type: 'number', headerName: 'Valor', width: 120, valueGetter: (params) => {
                  if (params?.row?.amount_user && params?.row?.amount_organization) {
                    return parseFloat(params?.row?.amount_user) + parseFloat(params?.row?.amount_organization);
                  } else if (params?.row?.amount_user) {
                    return params?.row?.amount_user;
                  } else if (params?.row?.amount_organization) {
                    return params?.row?.amount_organization;
                  } else {
                    return 0;
                  }
                }
              },
              {
                field: 'approved', type: 'string', headerName: 'Aprovado', width: 120, valueGetter: (params) => {
                  return formatDateTimeDay(params?.row?.approved_date);
                }
              },
            ]}
            showFilterIcon
          />
        )}
      </Container>
    </LayoutLogged>
  );
}